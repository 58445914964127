import React, { useState } from 'react'
import {
  Box,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  IconButton,
  styled,
  CircularProgress,
  Select,
  MenuItem
} from '@mui/material'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'

import ConfirmDeleteDialog from '../Delete/ConfirmDeleteDialog '
import moment from 'moment'

const StyledTable = styled(Table)(() => ({
  whiteSpace: 'nowrap',
  '& thead': {
    '& tr': { '& th': { paddingLeft: 0, paddingRight: 0 } }
  },
  '& tbody': {
    '& tr': { '& td': { paddingLeft: 0, textTransform: 'capitalize' } }
  }
}))

const SrNoTableCell = styled(TableCell)(() => ({
  width: '50px', // Adjust the width as needed
  textAlign: 'left'
}))

const SaleTable = ({
  loading,
  page,
  rowsPerPage,
  allSalesData,
  allClients,
  allProducts,
  hasPermission,
  totalSales,
  dropDownOptions,
  handleViewClick,
  handleEdit,
  handleUpdateSaleField,
  handleDeleteSale,
  handleChangePage,
  handleChangeRowsPerPage
}) => {
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [selectedSaleId, setSelectedSaleId] = useState(null)

  const handleConfirmDelete = () => {
    if (selectedSaleId) {
      handleDeleteSale(selectedSaleId)
    }
    setOpenDeleteDialog(false)
  }
  const handleCancelDelete = () => {
    setOpenDeleteDialog(false)
    setSelectedSaleId(null)
  }
  const handleDeleteClick = (itemId) => {
    setSelectedSaleId(itemId)
    setOpenDeleteDialog(true)
  }
  const ProductSelect = ({ product, onChange }) => (
    <Select
      value={product || 'N/A'}
      onChange={onChange}
      displayEmpty
      sx={{
        height: '30px',
        width: '125px'
      }}
    >
      {allProducts?.map((item) => (
        <MenuItem key={item.key} value={item.key}>
          {item.value}
        </MenuItem>
      ))}
    </Select>
  )
  const CustomerSelect = ({ customerName, onChange }) => (
    <Select
      value={customerName || 'N/A'}
      onChange={onChange}
      displayEmpty
      sx={{
        height: '30px',
        width: '125px'
      }}
    >
      {allClients?.map((client) => (
        <MenuItem key={client.key} value={client.key}>
          {client.value}
        </MenuItem>
      ))}
    </Select>
  )
  const SourceSelect = ({ source, onChange }) => (
    <Select
      value={source || 'N/A'}
      onChange={onChange}
      displayEmpty
      sx={{
        height: '30px',
        width: '125px'
      }}
    >
      {dropDownOptions?.sourceDropdownOptions?.map((source) => (
        <MenuItem key={source} value={source}>
          {source}
        </MenuItem>
      ))}
    </Select>
  )

  return (
    <Box width="100%" overflow="auto" mt="20px" mb="-10px">
      <StyledTable>
        <TableHead>
          <TableRow>
            <SrNoTableCell
              align="left"
              sx={{ width: { xs: '35px', sm: '35px', md: '35px' } }}
            >
              No.
            </SrNoTableCell>
            <TableCell
              align="left"
              sx={{ width: { xs: '140px', sm: '140px', md: '140px' } }}
            >
              Customer Name
            </TableCell>
            <TableCell
              align="center"
              sx={{ width: { xs: '100px', sm: '100px', md: '90px' } }}
            >
              Date{' '}
            </TableCell>
            <TableCell
              align="center"
              sx={{
                width: { xs: '110px', sm: '110px', md: '110px' }
              }}
            >
              Added By
            </TableCell>
            <TableCell
              align="center"
              sx={{
                width: { xs: '100px', sm: '100px', md: '100px' }
              }}
            >
              Tanker No
            </TableCell>
            <TableCell
              align="center"
              sx={{ width: { xs: '100px', sm: '100px', md: '100px' } }}
            >
              Invoice No
            </TableCell>
            <TableCell
              align="center"
              sx={{ width: { xs: '160px', sm: '160px', md: '160px' } }}
            >
              Company Name
            </TableCell>
            <TableCell
              align="center"
              sx={{ width: { xs: '150px', sm: '150px', md: '150px' } }}
            >
              Source
            </TableCell>
            <TableCell
              align="center"
              sx={{ width: { xs: '150px', sm: '150px', md: '150px' } }}
            >
              Product
            </TableCell>
            <TableCell
              align="center"
              sx={{ width: { xs: '150px', sm: '150px', md: '150px' } }}
            >
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <TableRow>
              <TableCell colSpan={10} align="center">
                <CircularProgress />
              </TableCell>
            </TableRow>
          ) : allSalesData.length > 0 ? (
            allSalesData.map((sale, index) => (
              <TableRow key={sale._id}>
                <SrNoTableCell>{page * rowsPerPage + index + 1}</SrNoTableCell>
                <TableCell align="left">
                  {hasPermission('Sales.Sales:edit') ? (
                    <CustomerSelect
                      customerName={sale.customerName._id}
                      onChange={(event) =>
                        handleUpdateSaleField(
                          event.target.value,
                          sale,
                          'customerName'
                        )
                      }
                    />
                  ) : (
                    sale?.customerName?.name
                  )}
                </TableCell>
                <TableCell align="center">
                  {moment(sale?.date).format('DD/MM/YYYY')}
                </TableCell>
                <TableCell align="center">{sale.addedBy}</TableCell>
                <TableCell align="center">{sale.tankerNo}</TableCell>
                <TableCell align="center">{sale.invoiceNo}</TableCell>
                <TableCell align="center">{sale.companyName}</TableCell>
                <TableCell align="center">
                  {hasPermission('Sales.Sales:edit') ? (
                    <SourceSelect
                      source={sale.source}
                      onChange={(event) =>
                        handleUpdateSaleField(
                          event.target.value,
                          sale,
                          'source'
                        )
                      }
                    />
                  ) : (
                    sale?.source
                  )}
                </TableCell>
                <TableCell align="center">
                  {hasPermission('Sales.Sales:edit') ? (
                    <ProductSelect
                      product={sale.product._id}
                      onChange={(event) =>
                        handleUpdateSaleField(
                          event.target.value,
                          sale,
                          'product'
                        )
                      }
                    />
                  ) : (
                    sale.product?.name
                  )}
                </TableCell>
                <TableCell align="center">
                  {hasPermission('Sales.Sales:view') && (
                    <IconButton
                      onClick={() => handleViewClick(sale)}
                      color="primary"
                      aria-label={`view ${sale.customerName?.name}`}
                    >
                      <RemoveRedEyeIcon />
                    </IconButton>
                  )}
                  {hasPermission('Sales.Sales:edit') && (
                    <IconButton
                      onClick={() => handleEdit(sale)}
                      color="primary"
                      aria-label={`edit ${sale.name}`}
                    >
                      <EditIcon />
                    </IconButton>
                  )}
                  {hasPermission('Sales.Sales:delete') && (
                    <IconButton
                      onClick={() => handleDeleteClick(sale._id)}
                      sx={{ color: 'red' }}
                      aria-label={`delete ${sale.name}`}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={10} align="center">
                No data available
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </StyledTable>

      <ConfirmDeleteDialog
        open={openDeleteDialog}
        onConfirm={handleConfirmDelete}
        onCancel={handleCancelDelete}
        itemName={
          allSalesData.find((sale) => sale._id === selectedSaleId)?.customerName
            .name
        }
      />
      <TablePagination
        sx={{ px: 2 }}
        component="div"
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        count={totalSales}
        rowsPerPageOptions={[5, 10, 20, 30]}
        nextIconButtonProps={{ 'aria-label': 'Next Page' }}
        backIconButtonProps={{ 'aria-label': 'Previous Page' }}
      />
    </Box>
  )
}

export default SaleTable
