import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Grid,
  Box,
  Typography,
  Divider
} from '@mui/material'
import PersonIcon from '@mui/icons-material/Person'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import ReceiptIcon from '@mui/icons-material/Receipt'
import BusinessIcon from '@mui/icons-material/Business'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket'
import SourceIcon from '@mui/icons-material/Source'
import ScaleIcon from '@mui/icons-material/Scale'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import OpacityIcon from '@mui/icons-material/Opacity'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import PaymentIcon from '@mui/icons-material/Payment'
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import CloseIcon from '@mui/icons-material/Close'

import moment from 'moment'

const SalesDetailsModel = ({
  detailsModalOpen,
  detailsModalData,
  handleViewClose
}) => {
  const getPaymentColor = (payment) => {
    switch (payment) {
      case 'Paid':
        return '#00e676'
      case 'Unpaid':
        return '#ff3d00'
      default:
        return 'inherit'
    }
  }
  const PaymentBadge = ({ payment }) => (
    <Box>
      <Typography
        sx={{
          marginLeft: '3px',
          color: getPaymentColor(payment),
          border: `1px solid ${getPaymentColor(payment)}`,
          display: 'inline-block',
          textAlign: 'center',
          width: '55px',
          borderRadius: '3px'
        }}
      >
        {payment}
      </Typography>
    </Box>
  )

  return (
    <Dialog open={detailsModalOpen} onClose={handleViewClose} maxWidth="md">
      <DialogTitle>
        Sales Details
        <Divider sx={{ my: 2 }} />
        <IconButton
          aria-label="close"
          onClick={handleViewClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {detailsModalData?._id ? (
          <Grid container spacing={{ xs: 0, sm: 0, md: 12 }}>
            <Grid item xs={12} sm={6} md={6}>
              <Box display="flex" alignItems="center" mb={2}>
                <PersonIcon />
                <Typography ml={1}>
                  Customer Name: {detailsModalData?.customerName?.name}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" mb={2}>
                <LocalShippingIcon />
                <Typography ml={1}>
                  Tanker No: {detailsModalData?.tankerNo}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" mb={2}>
                <ReceiptIcon />
                <Typography ml={1}>
                  Invoice No: {detailsModalData?.invoiceNo}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" mb={2}>
                <BusinessIcon />
                <Typography ml={1}>
                  Company Name: {detailsModalData?.companyName}
                </Typography>
              </Box>

              <Box display="flex" alignItems="center" mb={2}>
                <ShoppingBasketIcon />
                <Typography ml={1}>
                  Product: {detailsModalData?.product?.name}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" mb={2}>
                <SourceIcon />
                <Typography ml={1}>
                  Source: {detailsModalData.source}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" mb={2}>
                <ScaleIcon />
                <Typography ml={1}>
                  Quantity: {detailsModalData?.quantity}{' '}
                  {detailsModalData?.unit}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" mb={2}>
                <AttachMoneyIcon />
                <Typography ml={1}>
                  Full Rate (+18%):{' '}
                  {detailsModalData?.fullRate?.toLocaleString()}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Box display="flex" alignItems="center" mb={2}>
                <LocationOnIcon /> {/* Icon for state and city */}
                <Typography ml={1}>State: {detailsModalData?.state}</Typography>
              </Box>
              <Box display="flex" alignItems="center" mb={2}>
                <LocationOnIcon />
                <Typography ml={1}>City: {detailsModalData?.city}</Typography>
              </Box>
              <Box display="flex" alignItems="center" mb={2}>
                <CalendarTodayIcon />
                <Typography ml={1}>
                  Date: {moment(detailsModalData?.date).format('DD-MM-YYYY')}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" mb={2}>
                <OpacityIcon />
                <Typography ml={1}>
                  Density: {detailsModalData?.density}
                </Typography>
              </Box>

              <Box display="flex" alignItems="center" mb={2}>
                <PaymentIcon />
                <Typography ml={1} sx={{ display: 'flex' }}>
                  Payment:
                  <PaymentBadge payment={detailsModalData?.payment} />
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" mb={2}>
                <CurrencyRupeeIcon />
                <Typography ml={1}>
                  Paid Amount: {detailsModalData?.paidAmount?.toLocaleString()}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" mb={2}>
                <PersonAddIcon />
                <Typography ml={1}>
                  Added By:{' '}
                  {(detailsModalData?.user?.name).charAt(0).toUpperCase() +
                    detailsModalData?.user?.name.slice(1)}{' '}
                  ( {detailsModalData?.addedBy} )
                </Typography>
              </Box>
            </Grid>
          </Grid>
        ) : (
          <Typography>No sale details available.</Typography>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default SalesDetailsModel
