// src/SocketContext.js
import useAuth from 'app/hooks/useAuth'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { io } from 'socket.io-client'

// Create a context for the socket
const SocketContext = createContext()

// Set up the socket connection
const socket = io(
  process.env.REACT_APP_API_BASE_URL || 'http://localhost:9001',
  {
    transports: ['websocket']
  }
)

// Create a provider component
export const SocketProvider = ({ children }) => {
  const [leadsOverview, setLeadsOverview] = useState([])
  const { user } = useAuth()

  useEffect(() => {
    // Cleanup on unmount
    return () => {
      socket.disconnect()
    }
  }, [])

  // Listen for socket events related to different charts
  useEffect(() => {
    // Emit the user's role to the server on connection

    if (user?.id) {
      socket.emit('user_connected', { userId: user?.id, role: user?.role })
    }

    // Listen for leads overview data updates
    socket.on('leads_overview', (data) => {
      setLeadsOverview(data)
    })

    // Cleanup listeners on component unmount
    return () => {
      socket.off('leads_overview')
    }
  }, [socket, user])

  return (
    <SocketContext.Provider value={{ socket, leadsOverview }}>
      {children}
    </SocketContext.Provider>
  )
}

// Custom hook to use the socket in other components
export const useSocket = () => useContext(SocketContext)
